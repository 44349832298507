/**
 * Checks if the symbol is not a letter
 * @param {String} x Symbol
 * @returns {Boolean}
 */
const isSymbol = x => !x.match(/^[a-zA-Z0-9]$/);

const nameReducer = (acc, x) => isSymbol(x) ? acc + '-' : acc + x;

module.exports = [
  isSymbol,
  nameReducer
]