const initCarousel = require('./chunks/init-carousel');
const fillHotOffers = require('./chunks/fill-hot-offers');

function _onload() {
  
  if (document.getElementById('item-slider')) {
    const itemSlider = window.itemSlider = initCarousel('.item-slider');
  }

  if (document.getElementById('aside-listing')) {
    document.getElementById('aside-listing').innerHTML = fillHotOffers(2);
  }

  if (document.querySelector('.aside')) {
    document.querySelector('.aside').classList.add('stick-30');
  }

}

window.addEventListener('load', _onload);